<template>
	<div style="height: 100%">
		<v-layout v-if="accountingFirm.customer_analytic_uri" fill-height justify-center>
			<iframe style="width: 100%; max-width: 1280px" :src="accountingFirm.customer_analytic_uri"></iframe>
		</v-layout>
		<v-layout v-else align-center justify-center wrap fill-height>
			<v-flex v-t="'analytics.no_configuration'" shrink></v-flex>
		</v-layout>
	</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'AnalyticsModule',
	computed: {
		...mapState({
			accountingFirm: state => state.accountingFirm.selected
		})
	}
}
</script>
